import { useState } from "react";

import SidebarItem from "./SidebarItem";

/** Takes in array of dropDownItems to form drop down list that can be toggled to be open or closed */

function SidebarDropDown({ dropDownHeader, dropDownItems }) {
  const [dropDownState, setDropDownState] = useState({ display: "none" });

  function toggleHidden() {
    dropDownState.display === "none"
      ? setDropDownState({ display: "block" })
      : setDropDownState({ display: "none" });
  }

  return (
    <>
      <li className="group border-b-2">
        <button
          type="button"
          className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
          onClick={toggleHidden}
        >
          <span className="flex-1 ml-2 text-left whitespace-nowrap font-bold">
            {dropDownHeader}
          </span>
          {/* Drop down arrow */}
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <ul className="py-2 space-y-2" style={dropDownState}>
          {dropDownItems.map((itemText) => {
            return <SidebarItem itemText={itemText} key={itemText} />;
          })}
        </ul>
      </li>
    </>
  );
}

export default SidebarDropDown;
