import { Outlet } from "react-router-dom";
import ContentNav from "./ContentNav";

/**
 * Outlet shows whatever element of a route  that lies
 * within the route that defines ContentPage
 */
function ContentPage({ tabHeaders }) {
  return (
    <div className="grow p-4">
      <ContentNav tabHeaders={tabHeaders} />
      <Outlet />
    </div>
  );
}

export default ContentPage;
