import { NavLink } from "react-router-dom";
import { replaceUnderscoresWithSpaces } from "../../util";

function ContentTab({ tabHeader, link }) {

  return (
    <li className="nav-item flex-auto text-center">
      <NavLink
        to={link}
        className="nav-link w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:bg-gray-100"
        style={({ isActive }) => {
          return {
            borderBottomColor: isActive ? "#2563eb" : "",
            color: isActive ? "#2563eb" : "",
          };
        }}
      >
        {replaceUnderscoresWithSpaces(tabHeader)}
      </NavLink>
    </li>
  );
}

export default ContentTab;
