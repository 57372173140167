import { NavLink } from "react-router-dom";

/** Basic unit of side bar drop down list */
function SidebarItem({ itemText }) {
  return (
    <li>
      <NavLink
        to={itemText}
        className="flex items-center p-2 ml-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
        style={({ isActive }) => {
          return {
            background: isActive ? "#e5e7eb" : "",
          };
        }}
      >
        {itemText}
      </NavLink>
    </li>
  );
}

export default SidebarItem;
