import { useEffect, useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import SidebarDropDown from "./Sidebar/SidebarDropDown";

function SidebarNav({ PRODUCTS_INFO }) {
  const [processedInfoMap, setProcessedInfoMap] = useState(null);
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    processProductsInfo(PRODUCTS_INFO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function processProductsInfo(productsInfo) {
    // Sort out products into their respective categories
    let processingInfoMap = new Map();
    for (let productInfo of productsInfo) {
      let category = productInfo.productCategory;
      let productName = productInfo.productName;
      if (processingInfoMap.has(category)) {
        let currentProducts = processingInfoMap.get(category);
        processingInfoMap.set(category, [...currentProducts, productName]);
      } else {
        processingInfoMap.set(category, [productInfo.productName]);
      }
    }
    setProcessedInfoMap(processingInfoMap);
    let keys = processingInfoMap.keys();
    setCategoryArray(Array.from(keys));
  }

  return (
    <Sidebar>
      {categoryArray.map((category) => {
        return (
          <SidebarDropDown
            dropDownHeader={category}
            dropDownItems={processedInfoMap.get(category)}
            key={category}
          />
        );
      })}
    </Sidebar>
  );
}
export default SidebarNav;
