/** Component that wraps around side bar drop downs and items.
 * Background and height of side bar colour can be tweaked here */

function Sidebar(props) {
  return (
    <aside className="w-64" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800 h-full min-h-screen">
        <ul className="space-y-2">{props.children}</ul>
      </div>
    </aside>
  );
}

export default Sidebar;
