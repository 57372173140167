import axios from "axios";
import IframeResizer from "iframe-resizer-react";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../config";

function EmbedDashBoard({ dashboardName }) {
  const [dashboardLink, setDashboardLink] = useState(null);
  useEffect(() => {
    setupDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardName]);

  async function setupDashboard() {
    let dashboardURL = await getDashboardLink(dashboardName);
    setDashboardLink(dashboardURL);
  }

  async function getDashboardLink(dashboardName) {
    let response = await axios.post(BACKEND_URL, {
      queriedDashboard: dashboardName.toUpperCase(),
    });
    let dashboardURL = response.data;
    return dashboardURL;
  }

  return (
    <IframeResizer
      src={dashboardLink}
      style={{ width: "1px", minWidth: "100%" }}
      checkOrigin={["https://metabase.internal.ottermations.xyz"]}
    />
  );
}

export default EmbedDashBoard;
