import "./App.css";
import { NFT_HEADERS } from "./config";
import MainContent from "./components/MainContent";

function App() {
  let PRODUCTS_INFO = [
    {
      productName: "Boomers",
      tabHeaders: NFT_HEADERS,
      productCategory: "NFT",
    },
    {
      productName: "MMB",
      tabHeaders: NFT_HEADERS,
      productCategory: "NFT",
    },
    {
      productName: "MMF",
      tabHeaders: NFT_HEADERS,
      productCategory: "Token",
    },
  ];
  return (
    <div>
      <MainContent PRODUCTS_INFO={PRODUCTS_INFO} />
    </div>
  );
}

export default App;
