import ContentTab from "./ContentTab";

function ContentNav({ tabHeaders }) {
  // receive tabHeaders info as an array of String

  return (
    <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4">
      {tabHeaders.map((tabHeader) => {
        return (
          <ContentTab tabHeader={tabHeader} link={tabHeader} key={tabHeader} />
        );
      })}
    </ul>
  );
}

export default ContentNav;
