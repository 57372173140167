import ContentRouting from "./Routing/ContentRouting";
import SidebarNav from "./SidebarNav";

function MainContent({ PRODUCTS_INFO }) {
  return (
    <>
      <main className="flex">
        <SidebarNav PRODUCTS_INFO={PRODUCTS_INFO} />
        <ContentRouting PRODUCTS_INFO={PRODUCTS_INFO} />
      </main>
    </>
  );
}

export default MainContent;
