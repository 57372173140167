import ContentPage from "../ContentPage/ContentPage";
import EmbedDashBoard from "../EmbedDashboard";
import { Route, Routes } from "react-router-dom";

function ContentRouting({ PRODUCTS_INFO }) {
  /**
   * PRODUCTS_INFO structure is an array of products (as objects) with each object having the following keys:
   * 1. productName (String)
   * 2. tabHeaders (array of Strings)
   */
  return (
    <Routes>
      {PRODUCTS_INFO.map((productInfo) => {
        return (
          <Route
            path={productInfo.productName}
            key={productInfo.productName}
            element={<ContentPage tabHeaders={productInfo.tabHeaders} />}
          >
            {productInfo.tabHeaders.map((tabHeader) => {
              return (
                <Route
                  path={tabHeader}
                  key={tabHeader}
                  element={
                    <EmbedDashBoard
                      dashboardName={`${productInfo.productName}_${tabHeader}`}
                    />
                  }
                />
              );
            })}
          </Route>
        );
      })}
    </Routes>
  );
}

export default ContentRouting;
